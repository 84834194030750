import React, {Component, useContext} from 'react';
import PropTypes from 'prop-types';
import './NavBarUser.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {NavDropdown} from "react-bootstrap";
import {faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import loginService from '../../services/user.service';
import authContext from "../../helpers/authContext";

const NavBarUser = () => {
    const navDropdownTitle = (<span><FontAwesomeIcon icon={faUserCircle} size="2x" /></span>);
    const navigate = useNavigate();
    const { setAuthenticated } = useContext(authContext);

    function submitHandler() {
        loginService.logout();
        setAuthenticated(false);
        navigate('/login');
    }

    return (
        <div className="NavBarUser">
            <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown" align="end">
                <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin">Admin</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={submitHandler}>Logout</NavDropdown.Item>
            </NavDropdown>
        </div>
    )
}

NavBarUser.propTypes = {};

NavBarUser.defaultProps = {};

export default NavBarUser;
