import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './UserExchanges.scss';
import {Button, ButtonGroup, Card, Col, Dropdown, Row, Table} from "react-bootstrap";
import userExchangeService from "../../services/userExchange.service";
import UserExchangeEdit from "./UserExchangeEdit/UserExchangeEdit";
import UserExchangeDelete from "./UserExchangeDelete/UserExchangeDelete";

const UserExchanges = () => {

    // Load the users upon init
    const [userExchanges, setUserExchanges] = useState([]);
    useEffect(() => {
        loadExchanges();
    }, []);

    // Setup form variables
    const [userExchange, setUserExchange] = useState({});

    // Create
    const [showCreate, setShowCreate] = useState(false);

    function handleShowCreate() {
        setUserExchange({});
        setShowCreate(true);
    }

    function handleCloseCreate() {
        setShowCreate(false);
        loadExchanges();
    }

    // Edit
    const [showEdit, setShowEdit] = useState(false);

    function handleShowEdit(userExchange) {
        setUserExchange(userExchange);
        setShowEdit(true);
    }

    function handleCloseEdit() {
        setShowEdit(false);
        loadExchanges();
    }

    // Delete
    const [showDelete, setShowDelete] = useState(false);
    function handleShowDelete(userExchange) {
        setUserExchange(userExchange);
        setShowDelete(true);
    }

    function handleCloseDelete() {
        setShowDelete(false);
        loadExchanges();
    }

    // Functions
    function loadExchanges() {
        userExchangeService.getUserExchanges().then(userExchanges => {
            setUserExchanges(userExchanges);
        });
    }

    return (
        <div className="UserExchanges">
            <Card border="dark">
                <Card.Header>
                    <Row>
                        <Col className={"text-start"}><h2>Exchanges</h2></Col>
                        <Col className={"text-end"}>
                            <Button variant="primary" onClick={handleShowCreate}>Add</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Key</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userExchanges.map((userExchange) => (
                        <tr key={userExchange.user_exchange_id}>
                            <td>{userExchange.user_exchange_id}</td>
                            <td>{userExchange.name}</td>
                            <td>{userExchange.user_key}</td>
                            <td>
                                <Dropdown as={ButtonGroup}>
                                    <Button variant="primary" size={"sm"}>Actions</Button>
                                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleShowEdit(userExchange)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleShowDelete(userExchange)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card>

            { showCreate && <UserExchangeEdit show={showCreate} closeHandler={handleCloseCreate.bind(this)} userExchange={userExchange} /> }
            { showEdit && <UserExchangeEdit show={showEdit} closeHandler={handleCloseEdit.bind(this)} userExchange={userExchange} /> }
            { showDelete && <UserExchangeDelete show={showDelete} closeHandler={handleCloseDelete.bind(this)} userExchange={userExchange} /> }
        </div>
    );
}

UserExchanges.propTypes = {};

UserExchanges.defaultProps = {};

export default UserExchanges;
