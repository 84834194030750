import React, { Component, useContext, useState } from "react";
import PropTypes from "prop-types";
import "./Login.scss";
import { Col, Container, Form, Row, Button, Alert } from "react-bootstrap";
import logo from "../../assets/images/animus-black.png";
import loginService from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import authContext from "../../helpers/authContext";

const Login = () => {
  let username, password;
  const [show, setShow] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(authContext);

  function submitHandler(event) {
    event.preventDefault();
    let form = event.target.elements;
    loginService
      .login(form.username.value, form.password.value)
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("token", JSON.stringify(response.data));
        }
        setAuthenticated(true);
        navigate("/");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setErrorText(error.response.data.response);
          setShow(true);
        }
      });
  }

  return (
    <div className="Login">
      <Container>
        <Row className="justify-content-md-center">
          <Col sm={3}>
            <img src={logo} width="275" className="mb-4" alt="Animus Crypto" />
            <Form onSubmit={submitHandler}>
              {show && <Alert variant="danger">{errorText}</Alert>}
              <Form.Group className="mb-3" controlId="username">
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  ref={(ref) => {
                    username = ref;
                  }}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  ref={(ref) => {
                    password = ref;
                  }}
                  required
                />
              </Form.Group>
              <div className="d-grid gap-2">
                <Button variant="dark" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
