import React from "react";
import PropTypes from "prop-types";
import "./NavBar.scss";
import logo from "../../assets/images/animus-white-name.png";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBarUser from "../NavBarUser/NavBarUser";

const NavBar = (props) => {

    if (!props.isAuthenticated) {
        return (null);
    }

    return (
            <Navbar className={"NavBar"} bg="primary" variant="dark">
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={logo}
                        width="120"
                        className="ms-3 d-inline-block align-top"
                        alt="Animus Crypto"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">
                            Dashboard
                        </Nav.Link>
                        <Nav.Link as={Link} to="/bots">
                            Bots
                        </Nav.Link>
                        <Nav.Link as={Link} to="/trading-view">
                            Trading View
                        </Nav.Link>
                        <Nav.Link as={Link} to="/research">
                            Research
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <NavBarUser />
            </Navbar>

    )
};

NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;
