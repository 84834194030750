import React, { useState, useEffect } from "react";
import researchService from "../../services/research.service";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "./CryptoWatchList.scss";
import CryptoWatchInput from "../CryptoWatchInput/CryptoWatchInput";
import CryptoTickerDelete from "../CryptoTickerDelete/CryptoTickerDelete";

function CryptoWatchList() {
  const [data, setData] = useState([]);
  useEffect(() => {
    loadWatchList();
  }, []);

  function loadWatchList() {
    researchService.getCryptoWatch().then((data) => {
      setData(data);
    });
  }

  function handleWatchListCreate() {
    loadWatchList();
  }

  return (
    <>
      <h6>Cryptocurrencies</h6>
      <Table borderless hover size="sm" variant="dark" className="table">
        <thead>
          <tr>
            <th>Ticker</th>
            <th>Price</th>
            <th>% Change</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((tickerRow) => (
            <tr
              key={tickerRow.ticker}
              style={{
                color:
                  Math.sign(tickerRow.todays_chang_eperc) < 0
                    ? "#ef5350"
                    : "#4fdc8a",
              }}
            >
              <td>{tickerRow.ticker.slice(2, -3)}</td>
              <td>
                {" "}
                <NumberFormat
                  value={tickerRow.last_trade}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={5}
                  prefix={"$"}
                />
              </td>
              <td>
                {" "}
                <NumberFormat
                  value={tickerRow.todays_chang_eperc}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                />
              </td>
              <td>
                <CryptoTickerDelete
                  ticker={tickerRow}
                  closeHandler={loadWatchList.bind(this)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CryptoWatchInput closeHandler={handleWatchListCreate.bind(this)} />
    </>
  );
}

export default CryptoWatchList;
