import axios from 'axios';

class DashboardService {

    portfolioOverview() {
        return axios.get('/portfolio/overview').then(portfolio => {
            return portfolio.data;
        });
    }

    portfolioHistory() {
        return axios.get('/portfolio/history').then(history => {
            return history.data;
        });
    }

}

export default new DashboardService();