import React from "react";
import { Card, Button } from "react-bootstrap";
import CryptoStockToggle from "../CryptoStockToggle/CryptoStockToggle";
import CryptoGainLose from "../CryptoGainLose/CryptoGainLose";
import "./WinLose.scss";

function WinLose() {
  return (
    <div className="WinLose">
      <Card bg="dark" text="light">
        <Card.Header>Gainers and Losers</Card.Header>
        <Card.Body>
          <CryptoStockToggle />
        </Card.Body>
      </Card>
    </div>
  );
}

WinLose.propTypes = {};

WinLose.defaultProps = {};

export default WinLose;
