import React from 'react';
import PropTypes from 'prop-types';
import './BotDelete.scss';
import {Button, Form, Offcanvas} from "react-bootstrap";
import userBotService from "../../../services/userBot.service";

const BotDelete = (props) => {

    function submitHandler(event) {
        event.preventDefault();
        userBotService.deleteUserBot(props.bot.job_id).then(() => {
            props.closeHandler();
        });
    }

    return (
        <div className="BotDelete">
            <Offcanvas show={props.show} onHide={props.closeHandler} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Delete Bot</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3">
                            Are you sure you want to delete this bot?
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

BotDelete.propTypes = {};

BotDelete.defaultProps = {};

export default BotDelete;
