import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './EditUser.scss';
import {Button, Form, Offcanvas} from "react-bootstrap";
import userService from "../../../../services/user.service";

const EditUser = (props) => {

    let title = 'Create User';
    let isEdit = false;
    let username, firstName, lastName, email, password;
    if (props.user.user_id) {
        isEdit = true;
        title = 'Edit User';
        firstName = props.user.first_name;
        lastName = props.user.last_name;
        email = props.user.email;
    }

    function submitHandler(event) {
        event.preventDefault();
        let form = event.target.elements;
        if (isEdit) {
            userService.updateUser(props.user.user_id, form.firstName.value, form.lastName.value, form.email.value).then(() => {
                props.closeHandler();
            });
        } else {
            userService.createUser(form.username.value, form.firstName.value, form.lastName.value, form.email.value, form.password.value).then(() => {
                props.closeHandler();
            });
        }
    }

    return (
        <div className="EditUser">
            <Offcanvas show={props.show} onHide={props.closeHandler} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Control type="text" placeholder="Enter first name" ref={(ref) => {firstName = ref}} defaultValue={firstName} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Control type="text" placeholder="Enter last name" ref={(ref) => {lastName = ref}} defaultValue={lastName} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Control type="email" placeholder="Enter email" ref={(ref) => {email = ref}} defaultValue={email} required />
                        </Form.Group>
                        { !isEdit &&
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Control type="text" placeholder="Enter username" ref={(ref) => {username = ref}} required />
                            </Form.Group>
                        }
                        { !isEdit &&
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Control type="password" placeholder="Enter password" ref={(ref) => {password = ref}} required />
                            </Form.Group>
                        }
                        <div className="d-grid gap-2">
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
};

EditUser.propTypes = {};

EditUser.defaultProps = {};

export default EditUser;
