import React from 'react';
import PropTypes from 'prop-types';
import './ChangeUserPassword.scss';
import {Button, Form, Offcanvas} from "react-bootstrap";
import userService from "../../../../services/user.service";

const ChangeUserPassword = (props) => {

    // Setup form variables
    let password;

    function submitHandler(event) {
        event.preventDefault();
        let form = event.target.elements;
        userService.changeUserPassword(props.user.user_id, form.password.value).then(() => {
            props.closeHandler();
        });
    }

    return (
        <div className="ChangeUserPassword">
            <Offcanvas show={props.show} onHide={props.closeHandler} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Change Password</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Control type="password" placeholder="Enter password" ref={(ref) => {password = ref}} required />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

ChangeUserPassword.propTypes = {};

ChangeUserPassword.defaultProps = {};

export default ChangeUserPassword;
