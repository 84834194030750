import React from "react";
import PropTypes from "prop-types";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ResearchNavBar.scss";

const ResearchNavBar = () => {
  return (
    <div className="ResearchNavBar">
      <Navbar bg="light" variant="light">
        <Container>
          <Nav>
            <Nav.Link href="#news">News</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#crypto">Crypto</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#stocks">Stocks</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#options">Options</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

ResearchNavBar.propTypes = {};

ResearchNavBar.defaultProps = {};

export default ResearchNavBar;
