import React from "react";
import { Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./WatchList.scss";
import CryptoWatchList from "../CryptoWatchList/CryptoWatchList";
import StockWatchList from "../StockWatchList/StockWatchList";
const WatchList = () => {
  return (
    <div className="WatchList">
      <Card className="text-center">
        <Card.Header>Watch List</Card.Header>
        <Card.Body>
          <StockWatchList />
          <CryptoWatchList />
          <Button variant="primary">Visit Watch List</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

WatchList.propTypes = {};

WatchList.defaultProps = {};

export default WatchList;
