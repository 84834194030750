import React from "react";
import PropTypes from "prop-types";
import "./CryptoLinks.scss";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const CryptoLinks = () => {
  const CryptoSource = [
    {
      name: "CoinGecko",
      link: "https://www.coingecko.com/en",
    },
    {
      name: "CoinMarketCap",
      link: "https://coinmarketcap.com/",
    },
    {
      name: "Messari",
      link: "https://messari.io/",
    },
    {
      name: "Sanbase",
      link: "https://app.santiment.net/",
    },
    {
      name: "Glassnode Studio",
      link: "https://studio.glassnode.com/",
    },
    {
      name: "Altcoin Alert",
      link: "https://app.altcoinalert.com/",
    },
    {
      name: "Coinspeaker",
      link: "https://www.coinspeaker.com/",
    },
    {
      name: "Cointelegraph",
      link: "https://cointelegraph.com/",
    },
    {
      name: "TradingView",
      link: "https://www.tradingview.com/news/?market=cryptocurrencies",
    },
    {
      name: "Crypto Panic",
      link: "https://cryptopanic.com/"
    }
  ];

  return (
    <div className="CryptoLinks">
      <Card style={{ width: "18rem" }}>
        <Card.Header>Crypto</Card.Header>

        <ListGroup variant="flush">
          {CryptoSource.map((source) => (
            <ListGroup.Item key={source.name}>
              <div className="controls text-start">
                <a target="_blank" href={source.link}>
                  {source.name}
                </a>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  size="1x"
                  className="iconn"
                />
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
    </div>
  );
};

CryptoLinks.propTypes = {};

CryptoLinks.defaultProps = {};

export default CryptoLinks;
