import axios from 'axios';
import React from "react";

class UserExchangeService {

    getUserExchanges() {
        return axios.get('/user/exchange').then(userExchanges => {
            return userExchanges.data;
        });
    }

    createUserExchange(exchangeId, userKey, userPhrase, userSecret) {
        return axios.post('/user/exchange', {
            exchange_id: exchangeId,
            user_key: userKey,
            user_phrase: userPhrase,
            user_secret: userSecret
        });
    }

    updateUserExchange(userExchangeId, userKey, userPhrase, userSecret) {
        return axios.put('user/exchange/' + userExchangeId, {
            user_key: userKey,
            user_phrase: userPhrase,
            user_secret: userSecret
        });
    }

    deleteUserExchange(userExchangeId) {
        return axios.delete('user/exchange/' + userExchangeId);
    }
}

export default new UserExchangeService();