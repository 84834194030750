import React from "react";
import PropTypes from "prop-types";
import "./Research.scss";
import { Col, Container, Row } from "react-bootstrap";
import CryptoLinks from "../CryptoLinks/CryptoLinks";
import ResearchNavBar from "../ResearchNavBar/ResearchNavBar";
import WinLose from "../WinLose/WinLose";
import WatchList from "../WatchList/WatchList";
import SmallStockChart from "../SmallStockChart/SmallStockChart";
import SmallCryptoChart from "../SmallCryptoChart/SmallCryptoChart";
import NewsBox from "../NewsBox/NewsBox";
import StockTickerInput from "../TickerInputs/StockTickerInput";

const Research = () => {
  return (
    <div className="Research">
      <Container>
        <Row>
          <Col sm={9}>
            <Row>
              <ResearchNavBar />
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <SmallCryptoChart />
              </Col>
              <Col sm={6}>
                <SmallStockChart />
              </Col>
            </Row>
            <br />
            <Row>
              <NewsBox />
            </Row>
          </Col>
          <Col sm={3}>
            <Row>
              <WatchList />
            </Row>
            <br />
            <Row>
              <WinLose />
            </Row>
            <br />
            <Row>
              <StockTickerInput />
            </Row>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Research.propTypes = {};

Research.defaultProps = {};

export default Research;
