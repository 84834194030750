import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './BotList.scss';
import {Button, ButtonGroup, Card, Col, Dropdown, Row, Table} from "react-bootstrap";
import BotEdit from "../BotEdit/BotEdit";
import userBotService from "../../../services/userBot.service";
import BotDelete from "../BotDelete/BotDelete";
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import {faCheckCircle, faPauseCircle} from "@fortawesome/free-regular-svg-icons";
import {faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BotPause from "../BotPause/BotPause";
import BotResume from "../BotResume/BotResume";

const BotList = () => {

    // Load the users bots upon init
    const [userBots, setUserBots] = useState([]);
    useEffect(() => {
        loadBots();
    }, []);

    // Setup form variables
    const [bot, setBot] = useState({});

    // Create
    const [showCreate, setShowCreate] = useState(false);

    function handleShowCreate() {
        setBot({});
        setShowCreate(true);
    }

    function handleCloseCreate() {
        setShowCreate(false);
        loadBots();
    }

    // Edit
    const [showEdit, setShowEdit] = useState(false);

    function handleShowEdit(bot) {
        setBot(bot);
        setShowEdit(true);
    }

    function handleCloseEdit() {
        setShowEdit(false);
        loadBots();
    }

    // Delete
    const [showDelete, setShowDelete] = useState(false);
    function handleShowDelete(bot) {
        setBot(bot);
        setShowDelete(true);
    }

    function handleCloseDelete() {
        setShowDelete(false);
        loadBots();
    }

    // Pause
    const [showPause, setShowPause] = useState(false);
    function handleShowPause(bot) {
        setBot(bot);
        setShowPause(true);
    }

    function handleClosePause() {
        setShowPause(false);
        loadBots();
    }

    // Resume
    const [showResume, setShowResume] = useState(false);
    function handleShowResume(bot) {
        setBot(bot);
        setShowResume(true);
    }

    function handleCloseResume() {
        setShowResume(false);
        loadBots();
    }

    // Functions
    function loadBots() {
        userBotService.getUserBots().then(userBots => {
            setUserBots(userBots);
        });
    }

    return (
        <div className="BotList">
            <Card border="dark">
                <Card.Header>
                    <Row>
                        <Col className={"text-start"}><h2>Bots</h2></Col>
                        <Col className={"text-end"}>
                            <Button variant="primary" onClick={handleShowCreate}>Add</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Task</th>
                        <th>Next Run</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userBots.map((userBot) => (
                        <tr key={userBot.bot_id}>
                            <td>{userBot.bot_id}</td>
                            <td>{userBot.bot_name}</td>
                            <td>{userBot.task_name}</td>
                            <td>
                                { userBot.next_run_time != null && <SimpleDateTime dateFormat="MDY" dateSeparator="/"  timeSeparator=":">{userBot.next_run_time}</SimpleDateTime> }
                            </td>
                            <td>
                                { userBot.status_id === 1 && <span><FontAwesomeIcon icon={faSpinner} size="2x" /></span> }
                                { userBot.status_id === 2 && <span><FontAwesomeIcon icon={faPauseCircle} size="2x" /></span> }
                                { userBot.status_id === 3 && <span><FontAwesomeIcon icon={faExclamationCircle} size="2x" /></span> }
                                { userBot.status_id === 4 && <span><FontAwesomeIcon icon={faCheckCircle} size="2x" /></span> }
                            </td>
                            <td>
                                <Dropdown as={ButtonGroup}>
                                    <Button variant="primary" size={"sm"}>Actions</Button>
                                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleShowEdit(userBot)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleShowPause(userBot)}>Pause</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleShowResume(userBot)}>Resume</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleShowDelete(userBot)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card>

            { showCreate && <BotEdit show={showCreate} closeHandler={handleCloseCreate.bind(this)} bot={bot} /> }
            { showEdit && <BotEdit show={showEdit} closeHandler={handleCloseEdit.bind(this)} bot={bot} /> }
            { showPause && <BotPause show={showPause} closeHandler={handleClosePause.bind(this)} bot={bot} /> }
            { showResume && <BotResume show={showResume} closeHandler={handleCloseResume.bind(this)} bot={bot} /> }
            { showDelete && <BotDelete show={showDelete} closeHandler={handleCloseDelete.bind(this)} bot={bot} /> }
        </div>
    );
}

BotList.propTypes = {};

BotList.defaultProps = {};

export default BotList;
