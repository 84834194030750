import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './BotEdit.scss';
import {Button, Form, Offcanvas} from "react-bootstrap";
import userBotService from "../../../services/userBot.service";

const BotEdit = (props) => {
    let title = 'Create Bot';
    let isEdit = false;
    let taskId, taskFrequencyId, taskMinute;
    const taskFrequencies = [{ taskFrequencyId: 'hourly', name: 'Hourly'}];
    const minutes = Array.from(Array(60).keys());
    if (props.bot.bot_id) {
        isEdit = true;
        title = 'Edit Bot';
    }

    // Load the list of tasks upon init
    const [tasks, setTasks] = useState([]);
    const [userBot, setUserBot] = useState({});
    useEffect(() => {
        userBotService.getUserBotTasks().then(tasks => {
            setTasks(tasks);
        });
        if (isEdit) {
            userBotService.getUserBot(props.bot.bot_id).then(userBot => {
                setUserBot(userBot);
            });
        }
    }, []);

    function submitHandler(event) {
        event.preventDefault();
        let form = event.target.elements;
        if (isEdit) {
            userBotService.updateUserBot(props.bot.bot_id, form.taskFrequencyId.value, form.taskMinute.value).then(() => {
                props.closeHandler();
            });
        } else {
            userBotService.createUserBot(form.taskId.value, form.taskFrequencyId.value, form.taskMinute.value).then(() => {
                props.closeHandler();
            });
        }
    }

    return (
        <div className="BotEdit">
            <Offcanvas show={props.show} onHide={props.closeHandler} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={submitHandler}>
                        { isEdit &&
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder={userBot.bot_name} readOnly />
                            </Form.Group>
                        }
                        <Form.Group className="mb-3" controlId="taskId">
                            <Form.Label>Task</Form.Label>
                            <Form.Select ref={(ref) => {taskId = ref}} disabled={isEdit}>
                                {tasks.map((task) => (
                                    <option key={task.task_id} value={task.task_id}>{task.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskFrequencyId">
                            <Form.Label>Frequency</Form.Label>
                            <Form.Select ref={(ref) => {taskFrequencyId = ref}}>
                                {taskFrequencies.map((taskFrequency) => (
                                    <option key={taskFrequency.taskFrequencyId} value={taskFrequency.taskFrequencyId}>{taskFrequency.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskMinute">
                            <Form.Label>Start time (in minutes)</Form.Label>
                            <Form.Select ref={(ref) => {taskMinute = ref}}>
                                {minutes.map((minute) => (
                                    <option key={minute} value={minute}>{minute}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

BotEdit.propTypes = {};

BotEdit.defaultProps = {};

export default BotEdit;
