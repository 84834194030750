import axios from "axios";
import loginService from "../services/user.service";

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

const mainApi = axios.create({
  baseURL: "http://localhost:5000/",
});

const researchApi = axios.create({
  baseURL: "http://localhost:5100/",
});

researchApi.interceptors.request.use((request) => {
  const currentUser = loginService.getCurrentUser();
  if (currentUser) {
    request.headers.common.Authorization = `Bearer ${currentUser.access_token}`;
  }
  return request;
});

researchApi.interceptors.response.use(undefined, (error) => {
  const { status, data, config } = error.response;
  if (status === 401) {
    loginService.logout();
    window.location = "/login";
  }
  return Promise.reject(error);
});

export { mainApi, researchApi };
