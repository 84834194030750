import axios from 'axios';
import React from "react";

class UserBotService {

    getUserBotTasks() {
        return axios.get('/user/bot/tasks').then(userBotTasks => {
            return userBotTasks.data;
        });
    }

    getUserBot(botId) {
        return axios.get('/user/bot/' + botId).then(userBot => {
            return userBot.data;
        });
    }

    getUserBots() {
        return axios.get('/user/bot').then(userBots => {
            return userBots.data;
        });
    }

    createUserBot(taskId, taskFrequencyId, taskMinute) {
        return axios.post('/user/bot', {
            task_id: taskId,
            task_frequency_id: taskFrequencyId,
            task_minute: taskMinute
        });
    }

    updateUserBot(userBotId, taskFrequencyId, taskMinute) {
        return axios.put('user/bot/' + userBotId, {
            task_frequency_id: taskFrequencyId,
            task_minute: taskMinute
        });
    }

    deleteUserBot(jobId) {
        return axios.delete('user/bot/' + jobId);
    }

    pauseUserBot(jobId) {
        return axios.post('user/bot/' + jobId + '/pause');
    }

    resumeUserBot(jobId) {
        return axios.post('user/bot/' + jobId + '/resume');
    }

    getHistory() {
        return axios.get('user/bot/history').then(history => {
            return history.data;
        });
    }
}

export default new UserBotService();