import React, { useState, useEffect } from "react";
import CryptoGainLose from "../CryptoGainLose/CryptoGainLose";
import StockGainLose from "../StockGainLose/StockGainLose";
import { Button } from "react-bootstrap";

function CryptoStockToggle() {
  const [ctoggle, setCToggle] = useState(true);
  const [stoggle, setSToggle] = useState(false);

  const Toggle = () => (
    setCToggle(!ctoggle),
    // console.log(ctoggle),
    setSToggle(!stoggle)
    // console.log(stoggle)
  );
  return (
    <>
      {ctoggle && <CryptoGainLose />}
      {stoggle && <StockGainLose />}
      <Button variant="primary" onClick={Toggle}>
        Crypto / Stock
      </Button>
    </>
  );
}

export default CryptoStockToggle;
