import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './BotHistory.scss';
import userBotService from "../../../services/userBot.service";
import {Card, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPauseCircle} from "@fortawesome/free-regular-svg-icons";
import {faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";

const BotHistory = () => {
    // Load the history upon init
    const [history, setHistory] = useState([]);
    useEffect(() => {
        loadHistory();
    }, []);

    // Functions
    function loadHistory() {
        userBotService.getHistory().then(history => {
            console.log('history', history);
            setHistory(history);
        });
    }

    return (
        <div className="BotHistory">
            <Card border="dark">
                <Card.Header>
                    <Row>
                        <Col className={"text-start"}><h2>History</h2></Col>
                    </Row>
                </Card.Header>
                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Bot</th>
                        <th>Task</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {history.map((row) => (
                        <tr key={row.bot_history_id}>
                            <td>{row.bot_history_id}</td>
                            <td>{row.bot_name}</td>
                            <td>{row.task_name}</td>
                            <td>{row.start_time}</td>
                            <td>{row.end_time}</td>
                            <td>
                                { row.status_id === 1 && <span><FontAwesomeIcon icon={faSpinner} size="2x" /></span> }
                                { row.status_id === 2 && <span><FontAwesomeIcon icon={faPauseCircle} size="2x" /></span> }
                                { row.status_id === 3 && <span><FontAwesomeIcon icon={faExclamationCircle} size="2x" /></span> }
                                { row.status_id === 4 && <span><FontAwesomeIcon icon={faCheckCircle} size="2x" /></span> }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card>
        </div>
    )
};

BotHistory.propTypes = {};

BotHistory.defaultProps = {};

export default BotHistory;
