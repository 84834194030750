import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './UserExchangeEdit.scss';
import {Button, ButtonGroup, Form, Offcanvas, ToggleButton} from "react-bootstrap";
import userExchangeService from "../../../services/userExchange.service";

const UserExchangeEdit = (props) => {

    let title = 'Create Exchange';
    let isEdit = false;
    let userKey, userPassphrase, userSecret;

    const [radioValue, setRadioValue] = useState('1');
    const radios = [
        { name: 'Coinbase', value: '1' },
        { name: 'Kucoin', value: '2' }
    ];

    if (props.userExchange.user_exchange_id) {
        isEdit = true;
        title = 'Edit Exchange';
        userKey = props.userExchange.user_key;
        userPassphrase = props.userExchange.user_passphrase;
        userSecret = props.userExchange.user_secret;
    }

    function submitHandler(event) {
        event.preventDefault();
        let form = event.target.elements;
        if (isEdit) {
            userExchangeService.updateUserExchange(props.userExchange.user_exchange_id, form.userKey.value, form.userPassphrase.value, form.userSecret.value).then(() => {
                props.closeHandler();
            });
        } else {
            userExchangeService.createUserExchange(radioValue, form.userKey.value, form.userPassphrase.value, form.userSecret.value).then(() => {
                props.closeHandler();
            });
        }
    }

    return (
        <div className="UserExchangeEdit">
            <Offcanvas show={props.show} onHide={props.closeHandler} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3" controlId="firstName">
                            <ButtonGroup>
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant={'outline-primary'}
                                        name="radio"
                                        value={radio.value}
                                        checked={radioValue === radio.value}
                                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="userKey">
                            <Form.Control type="text" placeholder="Enter key" ref={(ref) => {userKey = ref}} defaultValue={userKey} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="userPassphrase">
                            <Form.Control type="text" placeholder="Enter passphrase" ref={(ref) => {userPassphrase = ref}} defaultValue={userPassphrase} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="userSecret">
                            <Form.Control type="text" placeholder="Enter secret" ref={(ref) => {userSecret = ref}} defaultValue={userSecret} required />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

UserExchangeEdit.propTypes = {};

UserExchangeEdit.defaultProps = {};

export default UserExchangeEdit;
