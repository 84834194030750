import React from 'react';
import PropTypes from 'prop-types';
import './UserProfile.scss';
import {Col, Container, Row} from "react-bootstrap";
import UserExchanges from "../UserExchanges/UserExchanges";
import UserDetails from "../UserDetails/UserDetails";

const UserProfile = () => (
  <div className="UserProfile">
      <Container>
          <Row>
              <Col sm={3}>
                  <UserDetails />
              </Col>
              <Col sm={6}>
                  <UserExchanges />
              </Col>
          </Row>
      </Container>
  </div>
);

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;
