import React, { useState, useEffect } from "react";
import researchService from "../../services/research.service";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "./CryptoGainLose.scss";

function CryptoGainLose() {
  const [gain, setGain] = useState([]);
  const [loss, setLoss] = useState([]);
  useEffect(() => {
    researchService.getCryptoGainers().then((gain) => {
      setGain(gain);
      // console.log(gain);
    });
    researchService.getCryptoLosers().then((loss) => {
      setLoss(loss);
      // console.log(loss);
    });
  }, []);

  return (
    <>
      <h6>Cryptocurrencies</h6>
      <Table borderless hover size="sm" variant="dark" className="table">
        <thead>
          <tr>
            <th>Ticker</th>
            <th>Price</th>
            {/* <th>Change</th> */}
            <th>% Change</th>
          </tr>
        </thead>
        <tbody>
          {gain.slice(0, 10).map((gainersRow) => (
            <tr
              key={gainersRow.ticker}
              style={{
                color:
                  Math.sign(gainersRow.todays_change_percent) < 0
                    ? "#ef5350"
                    : "#4fdc8a",
              }}
            >
              <td>{gainersRow.ticker.slice(2, -3)}</td>
              <td>
                {" "}
                <NumberFormat
                  value={gainersRow.last_trade.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={5}
                  prefix={"$"}
                />
              </td>
              <td>
                {" "}
                <NumberFormat
                  value={gainersRow.todays_change_percent}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                />
              </td>
            </tr>
          ))}
          {loss.slice(0, 10).map((losersRow) => (
            <tr
              key={losersRow.ticker}
              style={{
                color:
                  Math.sign(losersRow.todays_change_percent) < 0
                    ? "#ef5350"
                    : "#4fdc8a",
              }}
            >
              <td>{losersRow.ticker.slice(2, -3)}</td>
              <td>
                {" "}
                <NumberFormat
                  value={losersRow.last_trade.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={5}
                  prefix={"$"}
                />
              </td>

              <td>
                {" "}
                <NumberFormat
                  value={losersRow.todays_change_percent}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  suffix={"%"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default CryptoGainLose;
