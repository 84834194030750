import { createChart } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

export const ChartComponent = (props) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const { data } = props;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries();
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [props.data]);

  return <div ref={chartContainerRef} />;
};

const initialData = [
  { time: "2018-12-22", value: 56.21 },
  { time: "2018-12-23", value: 64.32 },
  { time: "2018-12-24", value: 55.62 },
  { time: "2018-12-25", value: 51.33 },
  { time: "2018-12-26", value: 44.22 },
  { time: "2018-12-27", value: 61.37 },
  { time: "2018-12-28", value: 63.45 },
  { time: "2018-12-29", value: 67.84 },
  { time: "2018-12-30", value: 52.73 },
  { time: "2018-12-31", value: 39.22 },
];

export function SmallStockChart() {
  return <ChartComponent data={initialData}></ChartComponent>;
}

export default SmallStockChart;
