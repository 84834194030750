import axios from 'axios';
import React from "react";

class UserService {

    getUsers() {
        return axios.get('/user').then(users => {
            return users.data;
        });
    }

    login(username, password) {
        return axios.post('/user/login', { username, password });
    }

    logout() {
        localStorage.removeItem('token');
    }

    createUser(username, firstName, lastName, email, password) {
        return axios.post('/user', {
            username: username,
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password
        });
    }

    updateUser(userId, firstName, lastName, email) {
        return axios.put('/user/' + userId, {
            first_name: firstName,
            last_name: lastName,
            email: email
        });
    }

    changeUserPassword(userId, password) {
        return axios.put('/user/' + userId + '/changePassword', { password: password });
    }

    deleteUser(userId) {
        return axios.delete('user/' + userId);
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('token'));
    }

    userHasToken() {
        return this.getCurrentUser() != null;
    }

    getMe() {
        return axios.get('/me').then(user => {
            return user.data;
        });
    }
}

export default new UserService();