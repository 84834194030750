import { researchApi } from "../helpers/apis";

class ResearchService {
  getNews() {
    return researchApi.get("/research/news").then((news) => {
      return news.data;
    });
  }

  getStocks() {
    return researchApi.get("/research/stocks").then((stocks) => {
      return stocks.data;
    });
  }

  getStockGainers() {
    return researchApi.get("/research/stockgainers").then((stocks) => {
      return stocks.data;
    });
  }

  getStockLosers() {
    return researchApi.get("/research/stocklosers").then((stocks) => {
      return stocks.data;
    });
  }

  getCryptoGainers() {
    return researchApi.get("/research/cryptogainers").then((crypto) => {
      return crypto.data;
    });
  }

  getCryptoLosers() {
    return researchApi.get("/research/cryptolosers").then((crypto) => {
      return crypto.data;
    });
  }

  getForexGainers() {
    return researchApi.get("/research/forexgainers").then((forex) => {
      return forex.data;
    });
  }

  getForexLosers() {
    return researchApi.get("/research/forexlosers").then((forex) => {
      return forex.data;
    });
  }

  getCryptoWatch() {
    return researchApi.get("/research/cryptowatch").then((crypto) => {
      return crypto.data;
    });
  }

  getStockWatch() {
    return researchApi.get("/research/stockwatch").then((stock) => {
      return stock.data;
    });
  }

  getStockTicker(ticker) {
    return researchApi
      .get("/research/stockticker?q=" + ticker)
      .then((stock) => {
        return stock.data;
      });
  }

  createCryptoWatch(ticker) {
    return researchApi.post("/research/cryptowatch", {
      ticker_symbol: ticker,
    });
  }

  createStockWatch(ticker) {
    return researchApi.post("/research/stockwatch", {
      ticker_symbol: ticker,
    });
  }

  deleteCryptoTicker(tickerID) {
    return researchApi.delete("/research/cryptowatch/" + tickerID);
  }

  deleteStockTicker(tickerID) {
    return researchApi.delete("/research/stockwatch/" + tickerID);
  }
}

export default new ResearchService();
