import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import axios from 'axios';
import loginService from './services/user.service';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(request => {
	const currentUser = loginService.getCurrentUser();
	if (currentUser) {
		request.headers.common.Authorization = `Bearer ${currentUser.access_token}`;
	}
	return request;
});

axios.interceptors.response.use(undefined, error => {
	const {status, data, config} = error.response;
	if (status === 401) {
		loginService.logout();
		window.location = '/login';
	}
	return Promise.reject(error);
});

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root')
);
