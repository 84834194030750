import React, { useState, useEffect, useRef } from "react";
import researchService from "../../services/research.service";
import { Form } from "react-bootstrap";
import "./CryptoWatchInput.scss";
import Button from "react-bootstrap/Button";
import axios from "axios";

const CryptoWatchInput = (props) => {
  const tickerInputRef = useRef();

  function submitHandler(event) {
    event.preventDefault();

    const enteredTicker = "X:" + tickerInputRef.current.value + "USD";

    researchService
      .createCryptoWatch(enteredTicker.toUpperCase())
      .then((result) => {
        props.closeHandler();
      });
  }
  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Crypto Ticker</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter ticker"
          ref={tickerInputRef}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default CryptoWatchInput;
