import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './UserDetails.scss';
import {Card} from "react-bootstrap";
import userService from '../../services/user.service';

const UserDetails = () => {

    // Load the users upon init
    const [user, setUser] = useState({});
    useEffect(() => {
        userService.getMe().then(user => {
            console.log(user);
            setUser(user);
        });
    }, []);

    return (
        <div className="UserDetails">
            <Card>
                <Card.Header as="h5">My Information</Card.Header>
                <Card.Body>
                    <Card.Title className={"text-start"}>{user.first_name} {user.last_name}</Card.Title>
                    <Card.Text>
                        <ul className={"text-start"}>
                            <li>Email - {user.email}</li>
                            <li>Username - {user.username}</li>
                            <li>Role - { user.role === 1 && 'Admin' }</li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

UserDetails.propTypes = {};

UserDetails.defaultProps = {};

export default UserDetails;
