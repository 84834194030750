import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Dashboard.scss";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Col, Row, Table } from "react-bootstrap";
import dashboardService from "../../services/dashboard.service";
import NumberFormat from "react-number-format";

const Dashboard = () => {
  const [overview, setOverview] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  function getTimeFromDate(createDate) {
    let date = new Date(createDate);
    return date.getTime();
  }

  function buildTimeSeries(history) {
    let cashValue = [];
    let stableValue = [];
    let cryptoValue = [];
    let portfolioValue = [];

    history.forEach((element) => {
      cashValue.push([
        getTimeFromDate(element.snapshot_date),
        element.cash_value,
      ]);
      stableValue.push([
        getTimeFromDate(element.snapshot_date),
        element.stable_value,
      ]);
      cryptoValue.push([
        getTimeFromDate(element.snapshot_date),
        element.crypto_value,
      ]);
      portfolioValue.push([
        getTimeFromDate(element.snapshot_date),
        element.portfolio_value,
      ]);
    });

    return {
      series: [
        {
          name: "Cash",
          data: cashValue,
        },
        {
          name: "Stable",
          data: stableValue,
        },
        {
          name: "Crypto",
          data: cryptoValue,
        },
        {
          name: "Portfolio",
          data: portfolioValue,
        },
      ],
    };
  }

  useEffect(() => {
    dashboardService.portfolioOverview().then((overview) => {
      setOverview(overview);
    });
    dashboardService.portfolioHistory().then((history) => {
      let newHistory = buildTimeSeries(history);
      setChartOptions(newHistory);
    });
  }, []);

  return (
    <div className="Dashboard">
      <Row>
        <Col>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={chartOptions}
          />
        </Col>
        <Col>
          <h2>My Portfolio</h2>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Exchange</th>
                <th>Asset</th>
                <th>Holdings</th>
                <th>Balance</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {overview.map((overviewRow) => (
                <tr key={[overviewRow.exchange, overviewRow.currencyId].join()}>
                  <td>{overviewRow.exchange}</td>
                  <td>{overviewRow.asset_name}</td>
                  <td>
                    <NumberFormat
                      value={overviewRow.percent * 100}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      suffix={"%"}
                    />
                  </td>
                  <td className="right-align">
                    <NumberFormat
                      value={overviewRow.balance}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix={"$"}
                    />
                  </td>
                  <td className="right-align">
                    <NumberFormat
                      value={overviewRow.quantity}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={7}
                      fixedDecimalScale={false}
                    />
                  </td>
                  <td className="right-align">
                    <NumberFormat
                      value={overviewRow.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={7}
                      prefix={"$"}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
