import React from 'react';
import PropTypes from 'prop-types';
import './UserExchangeDelete.scss';
import {Button, Form, Offcanvas} from "react-bootstrap";
import userExchangeService from "../../../services/userExchange.service";

const UserExchangeDelete = (props) => {

    function submitHandler(event) {
        event.preventDefault();
        userExchangeService.deleteUserExchange(props.userExchange.user_exchange_id).then(() => {
            props.closeHandler();
        });
    }

    return (
        <div className="UserExchangeDelete">
            <Offcanvas show={props.show} onHide={props.closeHandler} placement={"end"}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Delete Exchange</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3">
                            Are you sure you want to delete this exchange?
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

UserExchangeDelete.propTypes = {};

UserExchangeDelete.defaultProps = {};

export default UserExchangeDelete;
