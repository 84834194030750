import React from 'react';
import PropTypes from 'prop-types';
import './Admin.scss';
import {Col, Container, Row} from "react-bootstrap";
import UserAdmin from "./UserAdmin/UserAdmin";

const Admin = () => (
  <div className="Admin">
      <Container>
          <Row>
              <Col sm={6}>
                  <UserAdmin />
              </Col>
              <Col sm={6}>
                  Other admin components
              </Col>
          </Row>
      </Container>
  </div>
);

Admin.propTypes = {};

Admin.defaultProps = {};

export default Admin;
