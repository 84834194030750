import { React, useState, useRef, createRef } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import researchService from "../../services/research.service";
import StockWatchInput from "../StockWatchInput/StockWatchInput";
import { Form, Button } from "react-bootstrap";

// const SEARCH_URI = "http://localhost:5100/research/stockticker";

const StockTickerInput = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  // const tickerInputRef = useRef();
  const tickerInputRef = createRef();
  console.log(tickerInputRef);

  function submitHandler(event) {
    event.preventDefault();

    const enteredTicker = tickerInputRef.current.value;

    setSelected(enteredTicker);

    researchService.createStockWatch(selected).then((result) => {
      props.closeHandler();
    });
  }

  const handleSearch = (query) => {
    setIsLoading(true);

    researchService.getStockTicker(query).then((result) => {
      const options = result.map((i) => ({
        ticker: i.ticker,
        name: i.name,
      }));

      setOptions(options);
      setIsLoading(false);
    });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;
  function selectShow(stuff) {
    console.log("selectShow", stuff);
  }
  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Stock Ticker</Form.Label>
        <AsyncTypeahead
          filterBy={filterBy}
          id="async-example"
          isLoading={isLoading}
          ref={tickerInputRef}
          labelKey="ticker"
          minLength={1}
          onSearch={handleSearch}
          options={options}
          // onChange={setSelected()}
          placeholder="Enter ticker or stock..."
          renderMenuItemChildren={(option) => (
            <span>
              {option.name} ({option.ticker})
            </span>
          )}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    // {/* <button onClick={submitHandler}>Submit</button> */}
    // <button onClick={() => tickerInputRef.current.getInput()}>Submit</button>
  );
};

export default StockTickerInput;
