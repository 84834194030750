import React from "react";
import PropTypes from "prop-types";
import "./CryptoTickerDelete.scss";
import { Button, Form, Offcanvas } from "react-bootstrap";
import researchService from "../../services/research.service";

const CryptoTickerDelete = (props) => {
  console.log(props.ticker);
  function submitHandler(event) {
    event.preventDefault();
    console.log(event);
    researchService.deleteCryptoTicker(props.ticker.ticker_id).then(() => {
      props.closeHandler();
    });
  }

  return (
    <div className="CryptoTickerDelete">
      <Form onSubmit={submitHandler}>
        <div className="d-grid gap-2">
          <Button variant="dark" type="submit">
            x
          </Button>
        </div>
      </Form>
    </div>
  );
};

CryptoTickerDelete.propTypes = {};

CryptoTickerDelete.defaultProps = {};

export default CryptoTickerDelete;
