import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './Bots.scss';
import BotList from "./BotList/BotList";
import BotHistory from "./BotHistory/BotHistory";
import {Col, Container, Row} from "react-bootstrap";

const Bots = () => {

    return (
        <div className="Bots">
            <Container>
                <Row className="heading">
                    <Col>
                        <BotList />
                    </Col>
                </Row>
                <Row className="heading">
                    <Col>
                        <BotHistory />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

Bots.propTypes = {};

Bots.defaultProps = {};

export default Bots;
