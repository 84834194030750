import * as React from "react";
import "./App.scss";
import { TVChartContainer } from "./components/TVChartContainer/index";
import NavBar from "./components/NavBar/NavBar";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Bots from "./components/Bots/Bots";
import Research from "./components/Research/Research";
import Login from "./components/Login/Login";
import Admin from "./components/Admin/Admin";
import UserProfile from "./components/UserProfile/UserProfile";
import authContext from "./helpers/authContext";
import {useState} from "react";
import loginService from './services/user.service';

const App = () => {
    const [authenticated, setAuthenticated] = useState(loginService.userHasToken());
    return (
        <div className={"App"}>
            <authContext.Provider value={{ authenticated, setAuthenticated }}>
                <NavBar isAuthenticated={authenticated}/>
                <Routes>
                    <Route path="/" exact={true} element={<Dashboard />} />
                    <Route path='/login' element={<Login />} />
                    <Route path="/bots" element={<Bots />} />
                    <Route path="/trading-view" element={<TVChartContainer />} />
                    <Route path="/research" element={<Research />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/profile" element={<UserProfile />} />
                </Routes>
            </authContext.Provider>
        </div>
    )
}

export default App;
