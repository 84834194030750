import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './UserAdmin.scss';
import {
    ButtonGroup,
    Col,
    Table,
    Button,
    Dropdown,
    Offcanvas,
    Form,
    Card,
    Row, ListGroup, Alert
} from "react-bootstrap";
import userService from '../../../services/user.service';
import EditUser from "./EditUser/EditUser";
import DeleteUser from "./DeleteUser/DeleteUser";
import ChangeUserPassword from "./ChangeUserPassword/ChangeUserPassword";

const UserAdmin = () => {

    // Load the users upon init
    const [users, setUsers] = useState([]);
    useEffect(() => {
        loadUsers();
    }, []);

    // Setup form variables
    const [user, setUser] = useState({});

    // Create
    const [showCreate, setShowCreate] = useState(false);

    function handleShowCreate() {
        setUser({});
        setShowCreate(true);
    }

    function handleCloseCreate() {
        setShowCreate(false);
        loadUsers();
    }

    // Edit
    const [showEdit, setShowEdit] = useState(false);

    function handleShowEdit(user) {
        setUser(user);
        setShowEdit(true);
    }

    function handleCloseEdit() {
        setShowEdit(false);
        loadUsers();
    }

    // Delete
    const [showDelete, setShowDelete] = useState(false);
    function handleShowDelete(user) {
        setUser(user);
        setShowDelete(true);
    }

    function handleCloseDelete() {
        setShowDelete(false);
        loadUsers();
    }

    // Change password
    const [showChangePassword, setShowChangePassword] = useState(false);

    function handleShowChangePassword(user) {
        setUser(user);
        setShowChangePassword(true);
    }

    function handleCloseChangePassword() {
        setShowChangePassword(false);
        loadUsers();
    }

    // Functions
    function loadUsers() {
        userService.getUsers().then(users => {
            setUsers(users);
        });
    }

    return (
      <div className="UserAdmin">
          <Card border="dark">
              <Card.Header>
                  <Row>
                      <Col className={"text-start"}><h2>Users</h2></Col>
                      <Col className={"text-end"}>
                          <Button variant="primary" onClick={handleShowCreate}>Create</Button>
                      </Col>
                  </Row>
              </Card.Header>
              <Table striped bordered hover size="sm">
                  <thead>
                  <tr>
                      <th>ID</th>
                      <th>Username</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {users.map((user) => (
                      <tr key={user.user_id}>
                          <td>{user.user_id}</td>
                          <td>{user.username}</td>
                          <td>{user.first_name}</td>
                          <td>{user.last_name}</td>
                          <td>{user.email}</td>
                          <td>
                              <Dropdown as={ButtonGroup}>
                                  <Button variant="primary" size={"sm"}>Actions</Button>
                                  <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                  <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => handleShowEdit(user)}>Edit</Dropdown.Item>
                                      <Dropdown.Item onClick={() => handleShowChangePassword(user)}>Change Password</Dropdown.Item>
                                      <Dropdown.Item onClick={() => handleShowDelete(user)}>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                              </Dropdown>
                          </td>
                      </tr>
                  ))}
                  </tbody>
              </Table>
          </Card>

          { showCreate && <EditUser show={showCreate} closeHandler={handleCloseCreate.bind(this)} user={user} /> }
          { showEdit && <EditUser show={showEdit} closeHandler={handleCloseEdit.bind(this)} user={user} /> }
          { showDelete && <DeleteUser show={showDelete} closeHandler={handleCloseDelete.bind(this)} user={user} /> }
          { showChangePassword && <ChangeUserPassword show={showChangePassword} closeHandler={handleCloseChangePassword.bind(this)} user={user} /> }
      </div>
    )
};

UserAdmin.propTypes = {};

UserAdmin.defaultProps = {};

export default UserAdmin;
